<template>
  <div>
    <tw-header :title="$t('BreadCrumbs.Staff Application Contracts')" />

    <div class='staff_application_contracts_container tw_container'>
      <!-- パンくず 検索エリア -->
      <div class='top_area'>
        <tw-breadcrumbs v-if='breadcrumbs.length' :pages='breadcrumbs' />

        <tw-search-field
          ref='twSearchFieldRef'
          :isShow="displayItems !== null && typeof displayItems !== 'undefined'"
          :filters='filters'
          :params='params'
          :key-for-queries-and-filters='keyForQueriesAndFilters'
          @applyFilters='applyFilters'
        >
          <template slot='buttons'>

            <template v-if="displayItems !== null && typeof displayItems !== 'undefined' && displayItems.length > 0">
              <tw-table-column-setting
                :schemas='schemas'
                :tableId='tableId'
                @filteredSchemas='onFilterSchema'
              />
            </template>

            <tw-button type='secondary' icon='plus' @click='onCreateContract' class='plus'>
              Contract
            </tw-button>
          </template>
        </tw-search-field>
      </div>

      <tw-add-contract-drawer ref='twAddContractDrawerRef' @onCloseDrawer='onCloseDrawer' />

      <tw-table-system
        height='max-content'
        :schemas='filteredSchemas'
        :items='displayItems'
        rowKey='contractId'
        :pageSize='params.lslConfig.query.limit'
        :totalCount='totalCount'
        :offset='params.lslConfig.query.offset'
        :serverPagination='true'
        @paging='onPaging'
        :actions-menu='actionList'
        :isLoading='isLoading'
      />
    </div>
  </div>
</template>

<script>
import mixinEntityList from '@/utils/mixinEntityList';
import { collapseTransition } from '@/utils/nextFrame';
import TwSearchField from '@/components/organisms/TwSearchField';
import { $api } from '@/store/ApiClient';
import TwTableColumnSetting from '@/components/organisms/TwTableColumnSetting';
import _ from 'lodash';
import dayjs from 'dayjs';
import TwAddContractDrawer from '@/components/organisms/TwAddContractDrawer.vue';
import { getYearMonth } from '@/utils/date';
import {
  invoicePlans,
  signed,
  issued,
  expired,
  canceled
} from '@/dictionaries/applicationInvoiceAndContractConstantValues';
import filters from '@/dictionaries/filters/staff/applicationContractFilters.json';
import schemas from '@/dictionaries/staffApplicationContractsSchema.json';
import mixinApplicationContractActions from '@/utils/mixinApplicationContractActions';
import { GET_CONTRACTS } from '@/api/target/twInvoiceApi';
import { DEFAULT_PAGENATION } from 'lib-tw-common';

export default {
  name: 'StaffApplicationContracts',
  mixins: [mixinEntityList, collapseTransition, mixinApplicationContractActions],
  components: { TwAddContractDrawer, TwSearchField, TwTableColumnSetting },
  props: {
    history: Object
  },
  data() {
    return {
      schemas: schemas,
      filters: [],
      // テーブル表示のレスポンスデータのコピー
      displayItems: null,
      // オリジナルのレスポンスデータ
      items: null,
      params: {
        lslConfig: {
          ...GET_CONTRACTS,
          query: {
            companyId: undefined,
            plan: undefined,
            signedYearMonth: undefined,
            limit: DEFAULT_PAGENATION.LIMIT,
            offset: 0
          }
        }
      },
      actionList: [
        {
          label: 'Update',
          icon: 'icon_edit',
          onClick: this.onClickRow,
          isDisabled: (row) => {
            return row.status === expired.value || row.status === canceled.value;
          }
        },
        {
          label: 'Expire',
          icon: 'calendar_expire',
          onClick: this.onClickExpire,
          isDisabled: (row) => {
            return row.status !== issued.value;
          }
        },
        {
          label: 'Cancel',
          icon: 'circle_minus',
          onClick: this.onClickCancel,
          isDisabled: (row) => {
            return row.status !== signed.value;
          }
        }
      ],
      filteredSchemas: [],
      tableId: 'staffApplicationContracts',
      keyForQueriesAndFilters: 'STAFF_APPLICATION_CONTRACT',
      totalCount: 0,
      isLoading: false
    };
  },
  computed: {
    breadcrumbs() {
      return [
        { label: this.$t('BreadCrumbs.Dashboard'), to: '/' },
        { label: this.$t('BreadCrumbs.Staff Application Contracts') }
      ];
    }
  },
  created() {

    this.filters = filters.map(filter => {
      if (filter.key === 'plan') {
        filter.options = invoicePlans.map(item => {
          return {
            ...item,
            label: this.$t(`Label.${item?.label}`)
          };
        });
      }
      return filter;
    });

    // Store/LocalStorageに検索の値が保存されているかをチェック
    // 保存されていれば、applyFiltersでfetchContractsを呼ぶ
    const queriesFromStorage = this.$store.getters.getQueries(this.keyForQueriesAndFilters);
    if (typeof queriesFromStorage === 'undefined') this.fetchContracts();
  },
  methods: {

    onPaging({ currentPage }) {
      this.params.lslConfig.query.offset = currentPage * this.params.lslConfig.query.limit - this.params.lslConfig.query.limit;
      this.fetchContracts();
    },

    onClickRow(event, row) {
      const data = this.items.find(item => item.companyId === row.companyId);
      if (this.$refs.twAddContractDrawerRef) this.$refs.twAddContractDrawerRef.openDrawer({ ...data });
    },

    fetchContracts() {

      this.isLoading = true;

      const params = _.cloneDeep(this.params);

      if (params.lslConfig.query?.signedYearMonth) {
        params.lslConfig.query.signedYearMonth = dayjs(params.lslConfig.query.signedYearMonth).format('YYYYMM');
      }

      $api.request(params)
        .then(response => {
          if (response?.contracts) {

            this.totalCount = response.totalCount;

            this.items = _.cloneDeep(response?.contracts);
            this.displayItems = _.cloneDeep(response?.contracts);

            this.displayItems.forEach(item => {

              const planLabel = invoicePlans.find(invoicePlan => invoicePlan.value === item.plan);
              if (planLabel) item.plan = this.$t(`Label.${planLabel.label}`);

              if (item.invoiceCycle) item.invoiceCycle = this.$t('List.Monthly');

              if (item.signedStartMonth) item.signedStartMonth = getYearMonth(item.signedStartMonth);
              if (item.signedEndMonth) item.signedEndMonth = getYearMonth(item.signedEndMonth);
            });
          }
        })
        .catch(err => {
          this.$store.dispatch('SHOW_ALERT', err.message);
          if (this.$refs.twSearchFieldRef) this.$refs.twSearchFieldRef.onClearAllFiltersClick();
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    onCreateContract() {
      if (this.$refs.twAddContractDrawerRef) this.$refs.twAddContractDrawerRef.openDrawer();
    },

    // 検索を行います
    applyFilters(params) {
      this.params = params;
      this.fetchContracts();
    },

    onFilterSchema(filteredSchemas) {
      this.filteredSchemas = filteredSchemas;
    },

    onCloseDrawer(status) {
      if (status !== 'cancel') {
        this.fetchContracts();
      }
    },

    onClickExpire(event, row) {
      this.expireContract(row)
        .then(() => {
          this.fetchContracts();
        });
    },

    onClickCancel(event, row) {
      this.cancelContract(row)
        .then(() => {
          this.fetchContracts();
        });
    }
  }
};
</script>
