<template>
  <el-drawer :title="mode === 'create' ? $t('List.Create') : $t('List.Update')"
             :visible.sync='open'
             :append-to-body='true'
             custom-class='add_invoice_drawer'
             :before-close='handleClose'
             :size='640'>

    <div v-if='data' class='add_invoice_inner'>
      <el-form class='add_usage_charge' :model='data' ref='form' :rules='rules' label-position='left'>
        <el-form-item :label="$t('Label.Company ID')" prop='companyId' :label-width='labelWidth'>
          <div>
            <el-input
              v-model='data.companyId'
              type='text'
              autocomplete='off'
              :disabled='true'
            />
          </div>
        </el-form-item>

        <el-form-item :label="$t('Label.Company Name')" prop='companyShortName' :label-width='labelWidth'>
          <tw-company-suggest-input
            v-model='data.companyShortName'
            :label-width='labelWidth'
            :disabled="mode === 'update'"
            @select='onSelectCompanyShortName'
            @blur='onBlurCompanyShortName'
            @clear='onClearCompanyShortName'
            @change='onChangeCompanyShortName'
          />
        </el-form-item>

        <el-form-item :label="$t('List.Plan')" :label-width='labelWidth' prop='plan'>
          <el-select v-model='data.plan'
                     :options='planOptions'
                     placeholder='Select'
                     :disabled='data.status === issued.value'
                     @change='onChangePlan'>
            <el-option
              v-for='(plan, index) in planOptions'
              :key='plan.value'
              :label='plan.label'
              :value='plan.value'
              :disabled='index === 0'
            >
            </el-option>
          </el-select>
        </el-form-item>

        <el-form-item :label="$t('Label.Contract Range')"
                      prop='signedStartMonth'
                      :label-width='labelWidth'>

          <div class='month_range_datepicker'>
            <el-date-picker v-model='data.signedStartMonth'
                            type='month'
                            format='yyyy-MM'
                            placeholder='Start month'
                            popper-class='month_date_picker'
                            :disabled='data.status === issued.value'
                            @change='onChangeSignedStartMonth'
                            :pickerOptions='{
                              disabledDate: disableMonth,
                            }'
            />

            <span>to</span>

            <el-date-picker v-model='data.signedEndMonth'
                            type='month'
                            format='yyyy-MM'
                            placeholder='End month'
                            popper-class='month_date_picker'
                            :pickerOptions='{
                              disabledDate: disableMonth,
                            }'
            />
          </div>
        </el-form-item>

        <el-form-item v-if='data.plan !== authorizedUsagePlan.value'
                      :label="$t('Label.Monthly Charge Amount')"
                      prop='monthlyChargeAmount'
                      :label-width='labelWidth'>
          <el-input
            v-model.number='data.monthlyChargeAmount'
            type='number'
            min='0'
            autocomplete='off'
            :disabled='data.status === issued.value'
            clearable
          >
            <template slot='prepend'>￥</template>
          </el-input>
        </el-form-item>

        <el-form-item v-if='data.plan === usageBasedPlan.value' :label="$t('Label.Usage Charge Amount Per Flow')"
                      prop='usageChargeAmountPerFlow'
                      :label-width='labelWidth'>
          <el-input
            v-model.number='data.usageChargeAmountPerFlow'
            type='number'
            min='0'
            autocomplete='off'
            :disabled='data.status === issued.value'
            clearable
          >
            <template slot='prepend'>￥</template>
          </el-input>
        </el-form-item>
      </el-form>

      <div v-if='errorMessages' style='margin-top: 40px'>
        <el-alert
          title='Error'
          type='error'
        >
          <ul>
            <li v-for='(item, index) in errorMessages' :key='index' class=''>
              {{ item.message }}
            </li>
          </ul>
        </el-alert>
      </div>

      <div class='button'>
        <tw-button v-if="mode === 'create'" type='primary' @click='onCreateBtnClick' :disabled='loading'>
          Register
        </tw-button>
        <tw-button v-if="mode === 'update'" type='primary' @click='onUpdateBtnClick' :disabled='loading'>
          Update
        </tw-button>
      </div>

      <div v-if="mode === 'update'" class='expire_cancel_button_wrapper'>
        <template v-if='data.status === issued.value'>
          <h4>
            Expire Contract
          </h4>

          <span class='description'>
            This contract is planed to end on <strong>{{ data.signedEndMonth }}</strong>. Expire now?
          </span>

          <div class='expire_button'>
            <tw-button type='secondary' size='small' @click='onClickExpire' :disabled='loading'>
              <span class='expire_button-inner'>
                <img class='icon' src='@/assets/images/icons/calendar_expire.svg' width='12' height='14' alt='' />

                <span>Expire</span>
              </span>
            </tw-button>
          </div>
        </template>

        <template v-if='data.status === signed.value'>
          <h4>
            Cancel Contract
          </h4>

          <span class='description'>
            This action is irreversible. The contract will be shown as <strong>Cancelled</strong> in the Contract List.
          </span>

          <div class='cancel_button'>
            <tw-button type='secondary' size='small' @click='onClickCancel' :disabled='loading'>
              <span class='cancel_button-inner'>
                <img class='icon' src='@/assets/images/icons/circle_minus.svg' width='12' height='14' alt='' />

                <span>Cancel</span>
              </span>
            </tw-button>
          </div>
        </template>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { $api } from '@/store/ApiClient';
import TwButton from '@/components/atoms/TwButton';
import TwCompanySuggestInput from '@/components/molecules/TwCompanySuggestInput';
import dayjs from 'dayjs';
import mixinValidation from '@/utils/mixinValidation';
import { getYearMonth } from '@/utils/date';
import {
  invoicePlans,
  authorizedUsagePlan,
  usageBasedPlan,
  financialInstitutionPlan,
  packagePlan,
  monthly, issued, signed
} from '@/dictionaries/applicationInvoiceAndContractConstantValues.js';
import mixinApplicationContractActions from '@/utils/mixinApplicationContractActions';
import { serviceCode, POST_CONTRACT, UPDATE_CONTRACT } from '@/api/target/twInvoiceApi';

export default {
  name: 'TwAddContractDrawer',
  computed: {
    signed() {
      return signed;
    },
    issued() {
      return issued;
    }
  },
  mixins: [mixinValidation, mixinApplicationContractActions],
  components: {
    TwButton,
    TwCompanySuggestInput
  },
  props: {},
  data() {
    return {
      params: {
        lslConfig: {
          serviceCode: serviceCode,
          path: {
            contractId: ''
          }
        },
        data: {
          companyId: '',
          plan: '',
          invoiceCycle: monthly.value,
          signedStartMonth: '',
          signedEndMonth: '',
          monthlyChargeAmount: null,
          usageChargeAmountPerFlow: null
        }
      },
      open: false,
      loading: false,
      mode: 'create',
      data: null,
      labelWidth: '240px',
      planOptions: [],
      errorMessages: null,
      rules: {
        companyShortName: [
          {
            required: true,
            validationId: ['tw-isNotEmpty'],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'change'
          }
        ],
        plan: [
          {
            required: true,
            validationId: ['tw-isNotEmpty'],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'blur'
          }
        ],
        signedStartMonth: [
          {
            required: true,
            validationId: ['tw-isNotEmpty'],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'blur'
          }
        ],
        signedEndMonth: [
          {
            required: true,
            validationId: ['tw-isNotEmpty'],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'blur'
          }
        ],
        monthlyChargeAmount: [
          {
            required: true,
            validationId: [],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'blur'
          }
        ],
        usageChargeAmountPerFlow: [
          {
            required: true,
            validationId: [],
            lakeelMessageSourceFlg: true,
            validator: this.onEventValidation,
            trigger: 'blur'
          }
        ]
      },
      invoicePlans,
      authorizedUsagePlan,
      usageBasedPlan,
      financialInstitutionPlan,
      packagePlan
    };
  },
  created() {
    this.planOptions = invoicePlans.map(item => {
      return {
        ...item,
        label: this.$t(`Label.${item.label}`)
      };
    });
    this.planOptions.unshift({
      label: 'Select',
      value: ''
    });
  },
  methods: {

    // Function
    resetDialog() {

      this.data = {
        companyId: '',
        companyShortName: '',
        companyShortNameJp: '',
        plan: '',
        signedStartMonth: '',
        signedEndMonth: '',
        // Invoice CycleはいつもMONTHLY
        invoiceCycle: monthly.value,
        monthlyChargeAmount: null,
        usageChargeAmountPerFlow: null
      };

      this.errorMessages = null;
    },

    openDrawer(data) {
      this.resetDialog();

      if (data === undefined) {

        this.mode = 'create';

      } else {

        this.mode = 'update';
        this.data = data;

        this.data.signedStartMonth = data?.signedStartMonth ? getYearMonth(data.signedStartMonth) : '';
        this.data.signedEndMonth = data?.signedEndMonth ? getYearMonth(data.signedEndMonth) : '';
      }

      this.open = true;
    },

    handleClose() {
      this.$confirm('Are you sure you want to close this?')
        .then(() => {
          this.onCloseDrawer('cancel');
        })
        .catch(() => {
        });
    },

    // 現在よりも過去の月は選択不可
    disableMonth(month) {
      return dayjs().isAfter(month, 'month');
    },

    // Call API
    async _contractCreateOrUpdateRequest(requestMode) {
      const isValid = await this.validationCheck('form');
      this.loading = true;

      if (isValid) {
        this.loading = true;

        const params = this.$_.cloneDeep(this.params);

        if (requestMode === 'create') {

          params.lslConfig.apiCode = POST_CONTRACT.apiCode;
          delete params.lslConfig.path;

        } else if (requestMode === 'update') {

          params.lslConfig.apiCode = UPDATE_CONTRACT.apiCode;
          params.lslConfig.path.contractId = this.data.contractId;

        } else {

          throw new Error('Unknown Request Mode');
        }

        for (const key in this.data) {
          if (!this.data[key] || typeof this.data[key] === 'undefined') delete params.data[key];
          params.data[key] = this.data[key];
        }

        params.data.signedStartMonth = params.data.signedStartMonth ? dayjs(this.data.signedStartMonth).format('YYYYMM') : undefined;
        params.data.signedEndMonth = params.data.signedEndMonth ? dayjs(this.data.signedEndMonth).format('YYYYMM') : undefined;


        switch (this.data.plan) {
        case financialInstitutionPlan.value:
          delete params.data.usageChargeAmountPerFlow;
          break;
        case packagePlan.value:
          delete params.data.usageChargeAmountPerFlow;
          break;
        case authorizedUsagePlan.value:
          delete params.data.invoiceCycle;
          delete params.data.monthlyChargeAmount;
          delete params.data.usageChargeAmountPerFlow;
          break;
        }

        // 親コンポーネントから渡ってきたdataから不要なプロパティを削除
        delete params.data.companyShortName;
        delete params.data.companyShortNameJp;
        delete params.data.contractId;
        delete params.data.status;

        return $api.request(params)
          .then(response => {
            if (response) {
              this.$store.dispatch('SHOW_COMPLETED');
            }
          })
          .catch(err => {
            if (Array.isArray(err.response?.errors) && err.response?.errors[0]?.meta) {
              this.errorMessages = err.response.errors[0].meta;
              throw err;
            }

            if (err.response?.message) {
              this.$store.dispatch('SHOW_ALERT', err.response.message);
              throw err;
            }
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.commit('END_PROCESS');
        await this.$store.dispatch('SHOW_ALERT', 'Please check the input and try again.');
      }
    },

    // Create new contract
    createContract() {
      this._contractCreateOrUpdateRequest('create')
        .then(() => {
          this.onCloseDrawer('complete');
        });
    },

    // Update contract
    updateContract() {
      this._contractCreateOrUpdateRequest('update')
        .then(() => {
          this.onCloseDrawer('complete');
        });
    },

    // Event

    onCloseDrawer(status) {
      this.open = false;
      this.data = null;

      // cancel or complete
      this.$emit('onCloseDrawer', status);
    },

    onCreateBtnClick() {
      this.createContract();

    },

    onUpdateBtnClick() {
      this.updateContract();
    },

    onSelectCompanyShortName(item) {
      this.data.companyId = item.companyId;
      this.data.companyShortName = item.companyShortName;
    },

    onBlurCompanyShortName(validCompany) {
      if (typeof validCompany === 'undefined') {

        this.onClearCompanyShortName();

      } else if (!this.data.companyId || validCompany.companyId !== this.data.companyId) {

        this.data.companyId = validCompany.companyId;
      }
    },

    onClearCompanyShortName() {
      this.data.companyId = '';
    },

    onChangeCompanyShortName() {
      if (!this.data.companyShortName) {
        this.onClearCompanyShortName();
      }
    },

    // 選択するプランによってバリデーションを切り替える
    onChangePlan(selectedValue) {

      switch (selectedValue) {
      case financialInstitutionPlan.value:
        this.data.usageChargeAmountPerFlow = undefined;
        this.rules.monthlyChargeAmount[0].validationId = ['tw-isNotEmpty', 'tw-isNumber'];
        this.rules.usageChargeAmountPerFlow[0].validationId = [];
        break;
      case usageBasedPlan.value:
        this.rules.monthlyChargeAmount[0].validationId = ['tw-isNotEmpty', 'tw-isNumber'];
        this.rules.usageChargeAmountPerFlow[0].validationId = ['tw-isNotEmpty', 'tw-isNumber'];
        break;
      case packagePlan.value:
        this.data.usageChargeAmountPerFlow = undefined;
        this.rules.monthlyChargeAmount[0].validationId = ['tw-isNotEmpty', 'tw-isNumber'];
        this.rules.usageChargeAmountPerFlow[0].validationId = [];
        break;
      case authorizedUsagePlan.value:
        this.data.invoiceCycle = undefined;
        this.data.monthlyChargeAmount = undefined;
        this.data.usageChargeAmountPerFlow = undefined;
        this.rules.monthlyChargeAmount[0].validationId = [];
        this.rules.usageChargeAmountPerFlow[0].validationId = [];
        break;
      }
    },

    onChangeSignedStartMonth(value) {
      if (dayjs(value).isValid()) {
        this.data.signedEndMonth = dayjs(value).add(11, 'month');
      }
    },

    onClickExpire() {
      this.expireContract(this.data)
        .then(() => {
          this.onCloseDrawer('complete');
        });
    },

    onClickCancel() {
      this.cancelContract(this.data)
        .then(() => {
          this.onCloseDrawer('complete');
        });
    }
  }
};
</script>

<style lang='scss' scoped>
.add_invoice_drawer {

  .button {
    margin-top: 40px;
    display: flex;
    align-items: baseline;
    gap: 20px;
    justify-content: flex-end;
  }

  .el-form-item {
    margin-bottom: 30px;
  }

  .expire_cancel_button_wrapper {
    display: flex;
    flex-direction: column;
    background-color: $color_gray_300;
    padding: 0 32px 24px;
    margin-top: 40px;
    border-radius: 8px;

    .description {
      font-size: 14px;
      margin-bottom: 16px;
    }

    .expire_button,
    .cancel_button {
      text-align: right;
    }

    .expire_button-inner,
    .cancel_button-inner {
      display: flex;
      gap: 8px;
      align-items: center;
    }
  }
}

::v-deep .el-input-group__append,
::v-deep .el-input-group__prepend {
  color: $color_gray_600;
}

::v-deep .el-checkbox {
  .el-checkbox__label {
    font-size: 14px;
    font-weight: normal;
    //color: $color_gray_300;
  }
}

::v-deep .side_drawer {
  width: auto;

  > .inner {
    padding: 20px 44px 48px;
  }
}

.el-drawer__wrapper {
  background: rgba(0, 0, 0, 0.2);

  .el-drawer__body {

    > .add_invoice_inner {
      padding: 10px 40px 0 35px;
    }
  }

  .el-drawer,
  .el-drawer__body {
    overflow: visible;
  }
}

::v-deep .el-drawer {

  .el-drawer__header {
    padding: 25px 41px 24px;
    font-weight: bold;
    font-size: 24px;
    line-height: 33px;
    color: $color_black;
    border-bottom: 1px solid $color_gray_300;

    i {
      color: $color_gray_400;
    }
  }
}

.month_range_datepicker {
  display: flex;
  align-items: center;
  gap: 8px;
  word-break: keep-all;
}

.checkbox-line {
  width: 80px;
  border-top: 1px solid $color_gray_300;
  margin-top: 20px;
}

.add_on_wrapper {
  border-top: 1px solid $color_gray_300;
  margin-top: 30px;
}

::v-deep .el-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type=number] {
    appearance: textfield;
  }
}

</style>
